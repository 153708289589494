/* You can add global styles to this file, and also import other style files */

@import '~bootstrap/scss/bootstrap-reboot';
@import '~bootstrap/scss/bootstrap-grid';
@import '@volt/component-library/dist/volt-component-library/volt-component-library.css';
$custom-border-color: #508be4;
body,
html {
    // font-family: "Open Sans",sans-serif;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    height: calc(100% - 30px);
}

.my-custom-tooltip {
    max-width: unset !important;
}

.infoTemplate-line1 {
    font-size: 16px;
    font-family: Roboto;
}

.infoTemplate-line2 {
    font-size: 14px;
    font-family: Roboto;
}

.no-data {
    position: relative;
    font-weight: 500;
    font-size: 25px;
}

.help {
    font-size: 20px;
}

.donut-chart .highcharts-background {
    width: 300px !important;
}

.customBtn {
    border-radius: 0px !important;
    color: $custom-border-color !important;
    border: 2px solid $custom-border-color !important;
    box-shadow: none !important;
    line-height: 32px !important;
    font-weight: bold !important;
}

/* Custom dashboad */
fuel-app-dashboard {
    display: block;
    padding: 1rem;
}
.mat-select-panel#multiselectevent-panel {
    margin-left: 28px !important;
}
.list-view-el {
    margin-left: 20px;
    margin-top: 8px;
    cursor: pointer;
}
fuel-app-date-drop-down {
    .mat-form-field-appearance-outline .mat-form-field-outline {
        color: #C7D4DD !important;
        outline: none !important;
        > div {
            border-width: 1px !important;
        }
    }
}
fuel-app-graph-view {
    svg {
        g.highcharts-grid, g.highcharts-axis {
            display: none;
        }
    }
}
mat-card.mat-card {
    padding: 1rem !important;
}
#multiselectevent {
    &.mat-select {
        margin-top: -1px;
    }
    .mat-select-placeholder {
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 500;
    }
}

.app-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    &.full {
        >* {
            flex: 1;
        }
    }
}

.app-grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.app-grid-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (max-width: 959px) {
    fuel-app-dashboard {
        padding: 1rem;
    }
}

